
import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import signupback from '../images/signupback.jpg'



const Privacy = () => (
  <Layout>
   <div class="inner-page">
   <div class="slider-item overlay bg-overly " style={{backgroundImage:`url(${signupback})`}} >
    {/* <div class="slider-item " style="background-image: url('');"> */}
        <div class="container">
        <div class="flex flex-row slider-text items-center justify-center" style={{height:450}}>
        {/* <div class="flex-col items-center justify-center text-center col-sm-12 element-animate"> */}
          <h1 class="self-center text-white text-5xl">PRIVACY POLICY</h1>
        {/* </div> */}
      </div>
            
        </div>
    </div>
</div>
<div class="flex flex-row mx-4 md:px-8 md:mx-8">
    <div class="flex flex-col mx-auto my-5">
            <p>
                All Healthcare Specialists who provide Services through healthpeer.ng or healthpeer app are registered
                and licensed
                by the respective regulating bodies. They have also gone through our 5 step verification process. Your
                health and well being is of paramount importance to us.
            </p>
            <p>
                At Healthpeer.ng, we are committed to safeguarding the privacy of our website visitors and app users,
                this policy sets out how we may use and
                disclose health information about you and how you can access this information. Please review it
                carefully.
            </p>
            <p>
                Your information is solely used for providing health care service. By using
                this service, you consent to the collection and use of your information in accordance with this policy.
            </p>
            <div>
                <p>This policy covers:</p>
                <ul>
                    <li>Who we are</li>
                    <li>How is patient privacy protected</li>
                    <li>What information do we collect </li>
                    <li>What we use your personal data for</li>
                    <li>Sharing your personal data</li>
                    <li>Information You Share With Third Parties</li>
                    <li>Child Protection </li>
                    <li>Data security and transfers</li>
                    <li>Your rights in relation to personal data </li>
                    <li>How to contact us? </li>
                </ul>
                <p>
                    If you have any further questions about how we process your information,
                    please don't hesitate to get in touch by sending a mail to our Data Protection Officer:
                    dpo@healthpeer.ng
                </p>
            </div>
            <div>
                <h6>
                    Who we are?
                </h6>
                <p>
                    Health Peer mobile application is a unique innovation which allows the patient to have a
                    consultation with a verified medical practitioner on the phone. Without the hassle, the wait,and
                    without the need to visit the hospital, the whole process of Consultation, Diagnosis,
                    Laboratory test and Prescription is made more comfortable with the app.
                </p>
            </div>
            <div>
                <h6>
                    How is patient privacy protected?
                </h6>
                <p>
                    As the healthcare professionals providing online medical and clinical services through Health Peer
                    (the "Healthcare Professionals", "us", "we", "our"), we understand that information about you and
                    your health is personal. Because of this, we strive to maintain the confidentiality of your health
                    information. We continuously seek to safeguard that information through administrative, physical and
                    technical means and otherwise abide by applicable federal and state guidelines.
                </p>
                <p>We are required by law to:</p>
                <ul>
                    <li>Maintain the privacy of your health information.</li>
                    <li>Provide this Notice of our duties and privacy practices.</li>
                    <li>Abide by the terms of the Notice currently in effect.</li>
                    <li>Tell you if there has been a breach that compromises your health information.</li>
                </ul>
                <p>
                    We reserve the right to change our privacy practices and make the new practices useful for all the
                    information we maintain.
                    Revised notices will be posted on the healthpeer website and mobile application.
                </p>
            </div>
            <div>
                <h6>
                    What information do we collect?
                </h6>
                <p>
                    Upon using the application, you consent that Healthpeer collects the following information which is
                    categorised below:
                </p>
                <p>
                    <b>Personal Data:</b> This may include but not limited to name, phone number, and profile details
                    such as age, username and password, email address, etc
                </p>
                <p>
                    <b>Usage Data:</b> This may include consultation details such as the doctor(s) you interact with,
                    concerns and diagnosis, feedback, etc.
                </p>
                <p>
                    <b>Diagnostic Data:</b> These include technical details such as device model, operating system,
                    application version, etc.
                </p>
                <p>
                    <b>Log Data:</b> Include session details such as date, time and duration of the session, etc.
                </p>
                <p>
                    <b>Device and Browser Information:</b> When you access the Site from a computer or other device, we
                    may collect anonymous
                    information from that device, such as your Internet protocol address, browser type, connection speed
                    and access times (collectively, "Anonymous Information").
                </p>
                <p>
                    <b>Mobile Services:</b> We may collect non-personal information from your mobile device or computer.
                    This information is generally used to help us deliver the most relevant information to you. Examples
                    of information
                    that may be collected and used include how you use the application(s) and information about the type
                    of device or
                    computer you use. In addition, in the event our application(s) crashes on your mobile device we will
                    receive
                    information about your mobile device model software version and device carrier,
                    which allows us to identify and fix bugs and otherwise improve the performance of our
                    application(s).
                </p>
            </div>
            <div>
                <h6>
                    How do we use personal information?
                </h6>
                <p>
                    Personal data submitted by users will be used for the purposes specified in this
                    privacy policy or in relevant parts of the website and mobile application.
                </p>
                <p>We may use your personal information to:</p>
                <ul>
                    <li>Enable your use of the services available on the application</li>
                    <li>Send you general (non-marketing) communications</li>
                    <li>Send you email notifications</li>
                    <li>Provide third parties with statistical information about our users –
                        but this information will not be used to identify any individual user</li>
                    <li>Deal with enquiries and complaints made by or about you relating to the application, and we will
                        not without your express consent provide your personal information to any third parties for the
                        purpose of direct marketing.</li>
                    <li>Analyse data about your use of our products and services to, for example, troubleshoot bugs
                        within the App, forecast demand of service and to understand other trends in use, including
                        which features users use the most and find most helpful, and what features users require from
                        us. This does not involve making any decisions about you - it is only about improving our App so
                        that we can deliver better services to you. Strict confidentiality and data security provisions
                        will apply always.</li>
                    <li>Where necessary for safety, regulatory and/or compliance purposes, we may audit consultations
                        and your other interactions with our services. Strict confidentiality and data security
                        provisions will apply at all times to any such audit and access.</li>
                    <li>We may obtain and use data about your precise location where you give your consent (through
                        providing us access to your location), for example, to help direct you to the nearest pharmacy.
                        We may also derive your approximate location from your IP address.</li>
                </ul>
            </div>
            <div>
                <h6>
                    Sharing your personal data
                </h6>
                <p>
                    We take the information you provide to us so seriously, only where necessary for your treatment or
                    care,
                    do we may share your information with your other health and social care providers. For example,
                    specialist
                    referral services, therapists, pharmacists, hospitals, accident and emergency services, pathology
                    service providers,
                    diagnosis centres chosen by you for the purpose of imaging requests, and other health and care
                    bodies.
                    This may include sharing information with
                    such services for safeguarding purposes in accordance with our legal obligations.
                </p>
                <p>
                    We may preserve or disclose information about you to comply with a law, regulation, legal process,
                    or governmental request; to assert legal rights or defend against legal claims; or to prevent,
                    detect, or investigate illegal activity, fraud, abuse,
                    violations of our terms, or threats to the security of our services or the physical safety of any
                    person.
                </p>
                <p>
                    Except as described above, we will never share your personal information with any other party
                    without your consent.
                </p>
            </div>
            <div>
                <h6>
                    Information You Share With Third Parties
                </h6>
                <p>
                    This Site Privacy Policy applies only to information we collect through the Site and in email,
                    text and other electronic communications set through or in connection with the Site. This Site
                    Privacy Policy DOES NOT apply to information collected by any third party. When you click on links
                    on the Site, you may leave our Site. We are not responsible for the privacy practices of other
                    sites,
                    and we encourage you to read their privacy statements.
                </p>
            </div>
            <div>
                <h6>
                    Child Protection
                </h6>
                <p>
                    Users under 13 are not eligible to use healthpeer.ng services without the guidance of an adult. If
                    you learn that a minor has provided us with personal information without parental consent, please
                    contact us through our support team. If we become aware of this breach, we will immediately take
                    steps to
                    take off the information and terminate such user’s account on our platform.
                </p>
            </div>
            <div>
                <h6>
                    Data security and transfers
                </h6>
                <p>
                    We do not store your personal health data on your mobile device. We store all your personal health
                    data - including your primary care information, medication information and diagnostic information -
                    on secure servers.
                </p>
                <p>
                    Where you have chosen a password that enables you to access certain parts of our App, you are
                    responsible for keeping this password confidential. We ask you not to share the password with
                    anyone.
                </p>
                <p>
                    We do not store any credit or debit card information. Payments are processed via a third party
                    payment provider that is fully compliant with NCC Consumer Code and data security standards. Any
                    payment transactions are encrypted using SSL technology.
                </p>
                <p>
                    We encrypt data transmitted to and from the App. Once we have received your information, we will use
                    strict procedures and security features to try to prevent unauthorised access. We will take all
                    steps reasonably necessary to ensure that your data is treated securely and in accordance with this
                    privacy policy.
                </p>
            </div>
            <div>
                <h6>
                    Your rights in relation to personal data
                </h6>
                <p>
                    As indicated above, whenever we rely on your consent to process your personal data, you have the
                    right to withdraw your consent at any time by directly sending a mail to dpo@healthpeer.ng
                </p>
                <p>You also have specific rights to:</p>
                <ul>
                    <li>Understand and request a copy of the information we hold about you. Recordings of your
                        appointments with us and other medical notes can be accessed via the App. For other information,
                        you can make a request by email</li>
                    <li>Ask us to rectify or erase information we hold about you, subject to limitations relating to our
                        obligation to store medical records for prescribed periods of time</li>
                    <li>Ask us to restrict our processing of your personal data or object to our processing </li>
                    <li>Ask for your data to be provided on a portable basis.</li>
                </ul>
            </div>
            <div>
                <h6>
                    How to contact us?
                </h6>
                <p>
                    For any questions or concerns, you can contact us by sending an email to hi@healthpeer.ng or healthpeerng@gmail.com
                </p>
            </div>
           

    </div>
</div>
    
  </Layout>
)

export default Privacy




